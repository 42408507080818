export type Document = {
  Id: string;
  FileUploadId?: string;
  FileName?: string;
  DocumentType?: string;
  DocumentCategory?: {
    Id: string;
    Name: string;
  };
  CreatedDate?: string;
  IsVisibleOnMobile?: boolean;
};

export type DocumentFilters = Partial<{
  Search: string;
}>;

export enum DocumentSort {
  Id = "Id",
  FileName = "FileName",
  DocumentType = "Type",
  LastChanged = "LastChanged",
  MobileVisibilityStatus = "MobileVisibilityStatus",
}

export type Category = {
  Id: string;
  Name: string;
};
